import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  login (query) {
    return Vue.http.post(API_BASE_URL + 'v1/login?' + query);
  },
  get_reports (query, data) {
    let route = '';
    if (query) {
      route = 'v1/get_reports?' + query;
    } else {
      route = 'v1/get_reports';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  install (query, data) {
    let route = '';
    if (query) {
      route = 'v1/install?' + query;
    } else {
      route = 'v1/install';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  }
};

